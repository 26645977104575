import { ArchwizardModule, WizardComponent } from "@achimha/angular-archwizard";
import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { AfterViewChecked, ChangeDetectorRef, Component, effect, EventEmitter, input, model, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { FeatureKeys, FeatureService } from "admin/components/features/feature.service";
import { CharTypeId, ConstUtils } from "rl-common/consts";
import { ICharDataExtDataAlert } from "rl-common/models/i-char-data-ext-data-alert";
import { ICharacteristicData } from "rl-common/models/i-characteristic-data";
import { ICharacteristicMetaDataCollection } from "rl-common/models/i-characteristic-meta-data-collection";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { EntityService } from "rl-common/services/entity/entity.service";
import { LayoutsService } from "rl-common/services/layouts/layouts.service";
import { MachineLearningService } from "rl-common/services/ml/ml.service";
import { OneConfigService } from "rl-common/services/one-config/one-config.service";
import { SessionService } from "rl-common/services/session.service";
import { AclUtil } from "rl-common/utils/acl.util";
import { CharTypeIdUtil } from "rl-common/utils/char-type-id.util";
import { IDUtil } from "rl-common/utils/id.util";
import { of, Subscription } from "rxjs";
import { filter, map, switchMap, take, tap } from "rxjs/operators";
import { FeatureEnabledDirective } from "../../directives/feature-enabled.directive";
import { CharDataTableComponent } from "../char-data";
import { ICharDataChangedEvent } from "../char-data/char-data.models";
import { CmdVisibilityService } from "../char-data/cmd-visibility.service";
import { IModLayout } from "../mod-details-layout/mod-layout.models";
import { ModLayoutCharDataTableComponent } from "../mod-layout-char-data-table/mod-layout-char-data-table.component";
import { LoaderComponent } from "../panel/loader/loader.component";
import { AssociateContactsComponent } from "./associate-contacts.component";
import { CreateAndAssociateHierarchicalRecordsComponent } from "./create-and-associate-hierarchical-records/create-and-associate-hierarchical-records.component";
import { CreateEntityCmdVisibilityService } from "./create-entity-cmd-visibility.service";
import { ITemplateHierarchy, OnEntityCreatedEvent } from "./create-entity.models";
import { ShowOnlyDropdownComponent } from "./show-only-dropdown/show-only-dropdown.component";

@Component({
	selector: "rl-create-entity",
	templateUrl: "./create-entity.component.html",
	styleUrls: ["./create-entity.component.scss"],
	providers: [CmdVisibilityService, CreateEntityCmdVisibilityService],
	imports: [ArchwizardModule, NgIf, ReactiveFormsModule, NgFor, ShowOnlyDropdownComponent, FeatureEnabledDirective, ModLayoutCharDataTableComponent, LoaderComponent, CharDataTableComponent, AssociateContactsComponent, CreateAndAssociateHierarchicalRecordsComponent, AsyncPipe]
})
export class CreateEntityComponent implements OnInit, AfterViewChecked, OnDestroy {

	charTypeId = input.required<number>();
	templateId = model.required<number>();
	parentId = input<string>();

	// this variable is for disabling everything BUT the char data table for initial charType/template
	isSimpleMode = model<boolean>(false);
	templatesIn = input<ICharacteristicTemplate[]>([]);
	sourceId = input<string>();

	@Output()
	onEntityCreated = new EventEmitter<OnEntityCreatedEvent>();

	@Output()
	onCancelEvent = new EventEmitter<void>();

	@Output()
	onCharDataUpdate = new EventEmitter<ICharDataChangedEvent>();

	@ViewChild(WizardComponent)
	wizard: WizardComponent;

	templates: ICharacteristicTemplate[] = [];
	childTemplates: ITemplateHierarchy[] = [];

	chooseTemplate: UntypedFormControl;

	template: ICharacteristicMetaDataCollection;
	charData: ICharacteristicData[];
	localAlerts: ICharDataExtDataAlert[];

	isCreating = false;
	charTypeDisplayName: string;
	createdEntityId: string;
	recordId: number;

	canAutoCreateHierarchy = false;
	autoCreateHierarchy = false;
	canAssociateContacts = false;
	saveWithContacts: boolean = false;
	subs: Subscription[] = [];
	confidenceScores: Map<number, number>;
	charDataContext: Map<number, string>;
	parties: Map<number, string[]>;
	catalogItems: Map<number, string[]>;

	@ViewChild(CharDataTableComponent, { static: false })
	charDataTable: CharDataTableComponent;

	@ViewChild(ModLayoutCharDataTableComponent, { static: false })
	modLayoutCharDataTable: ModLayoutCharDataTableComponent;

	@ViewChild(AssociateContactsComponent)
	associateContacts: AssociateContactsComponent;

	useFieldSections$ = this._featureService.isEnabled$(FeatureKeys.ConfigurableLayouts).pipe(
		take(1),
		map(isEnabled => isEnabled && ConstUtils.isModuleLevelCharType(this.charTypeId()))
	);

	layout: IModLayout;
	layoutSub: Subscription;

	extractedCharIds: number[] = null;

	get isValid() {
		return (this.charDataTable && this.charDataTable.valid) || (this.modLayoutCharDataTable && this.modLayoutCharDataTable.valid);
	}

	constructor(private readonly _oneConfigService: OneConfigService,
		private sessionService: SessionService,
		private formBuilder: UntypedFormBuilder,
		private entityService: EntityService,
		private router: Router,
		private mlService: MachineLearningService,
		private cd: ChangeDetectorRef,
		private readonly _featureService: FeatureService,
		private readonly _layoutsService: LayoutsService,
		cmdVisibilityService: CmdVisibilityService,
		readonly createCmdVisibilityService: CreateEntityCmdVisibilityService
	) {
		effect(() => {
			const charTypeId = this.charTypeId();
			const templateId = this.templateId();
			if (charTypeId && templateId) {
				this.layout = null;
				this.layoutSub?.unsubscribe();
				this.layoutSub = this.useFieldSections$.pipe(
					switchMap(useFieldSections => useFieldSections ? this._layoutsService.getLayout(charTypeId, templateId) : of(null)),
					map(x => x?.layout)
				).subscribe((layout) => {
					this.layout = layout;
				});
			}
		});

		cmdVisibilityService.setStrategy(createCmdVisibilityService);
	}

	ngOnInit() {
		this.chooseTemplate = this.formBuilder.control(this.templateId());

		this.charTypeDisplayName = CharTypeIdUtil.toDisplayName(this.charTypeId());
		this.switchTemplate(this.templateId());
		this.canAssociateContacts = this.charTypeId() !== CharTypeId.User && this.charTypeId() !== CharTypeId.Right && this.charTypeId() !== CharTypeId.Usage && this.charTypeId() !== CharTypeId.Amount;

		if (this.templatesIn()?.length > 0) {
			this.templates = this.templatesIn();
			this.isSimpleMode.set(false);
		} else {
			this.templates = this._oneConfigService.getTemplates(this.charTypeId());
		}
		const acl = this.sessionService.acls;
		this.templates = this.templates.filter(template => AclUtil.hasCreateAccess(acl, template.acl));

		if (this.sourceId()) {
			this.mlService.getResults(this.charTypeId(), this.templateId(), this.sourceId()).pipe(
				tap((response) => {
					this.charData = response.charData;
					this.confidenceScores = response.confidenceScores;
					this.extractedCharIds = response.charData.map(x => x.charactersticID);
					this.charDataContext = response.charDataContext;
					this.parties = response.parties;
					this.catalogItems = response.catalogItems;
				})
			).subscribe();
		}
	}

	ngAfterViewChecked() {
		this.cd.detectChanges();
	}

	showExtractFromPdf() {
		return this.charTypeId() == CharTypeId.Transaction && !this.sourceId();
	}

	charDataUpdated(event: ICharDataChangedEvent) {
		this.onCharDataUpdate.emit(event);
		this.charData = event.charData;
		this.localAlerts = event.alerts;
	}

	switchTemplate(tid: number) {
		this.templateId.set(tid);

		this.template = this._oneConfigService.getTemplateMetaData(this.charTypeId(), tid);
		this.charData = [];

		if (this.charTypeId() === CharTypeId.Property) {

			const childTemplates = this._oneConfigService.getChildAssocTemplates(this.charTypeId(), this.templateId(), this.charTypeId());
			this.canAutoCreateHierarchy = childTemplates && childTemplates.length > 0;
			this.childTemplates = childTemplates.map(t => ({
				directParentTemplates: [this.templateId()],
				templateID: t.templateID,
				templateLabel: t.templateName,
				childTemplates: null,
				level: 1,
				numberOfRecords: 0
			}));
		}
	}

	changeTemplate() {
		this.switchTemplate(+this.chooseTemplate.value);
	}

	create(templates: ITemplateHierarchy[] = []) {
		this.isCreating = true;
		this.chooseTemplate.disable();
		const sub = this.entityService.createEntityDeprecated(this.charTypeId(), this.templateId(), this.charData, this.parentId(), {}, this.localAlerts, this.sourceId()).pipe(
			map((response) => {
				const entityIdResponse = response.entityId;
				const splitId = IDUtil.splitEntityID(entityIdResponse);
				this.recordId = splitId.recID;
				this.createdEntityId = entityIdResponse;
				if (this.saveWithContacts) {
					return true;
				} else {
					this.onEntityCreated.emit({ newEntityId: this.createdEntityId, templateId: this.templateId() });
				}
			}),
			filter((result) => result === true),
			switchMap(() => this.associateContacts.saveContacts(this.createdEntityId)),
			map(() => {
				if (templates.length < 1) {
					this.onEntityCreated.emit({ newEntityId: this.createdEntityId, templateId: this.templateId() });
				}
			}),
			filter(() => templates.length > 0),
			switchMap(() => this.entityService.createAndAssociateHierarchicalRecords(this.createdEntityId, templates))
		).subscribe(() => {
			this.onEntityCreated.emit({
				newEntityId: this.createdEntityId,
				templateId: this.templateId()
			});
		});
		this.subs.push(sub);
	}

	cancel() {
		this.onCancelEvent.emit();
	}

	extractPdf() {
		const nextPage = `extract-pdf?templateId=${this.templateId}&charTypeId=${this.charTypeId}`;
		this.router.navigateByUrl(nextPage);
	}

	continue() {
		this.wizard.goToNextStep();
		this.saveWithContacts = true;
	}

	continueToNextStep() {
		this.wizard.goToNextStep();
	}

	back() {
		this.wizard.goToPreviousStep();
	}

	ngOnDestroy(): void {
		this.subs.forEach(sub => sub.unsubscribe());
		this.layoutSub?.unsubscribe();
	}
}
