<aw-wizard class="rl-wizard mx-3 mt-3" navBarLayout="small" [disableNavigationBar]="true">
	<aw-wizard-step stepTitle="Create New Record">
		<div class="modal-header" *ngIf="template && isSimpleMode()">
			<h2 class="modal-title">Create New {{template.template.templateName}}</h2>
			<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()">
				<i class="far fa-times"></i>
			</button>
		</div>
		<article [class.modal-body]="isSimpleMode()">
			<div [class.panel]="!isSimpleMode()" class="mt-2">
				<div class="d-inline-flex align-items-center pb-3" *ngIf="!isSimpleMode()" data-cy="template_selection">
					<h4 class="text-nowrap mb-0 me-2">Create new</h4>
					<select id="templateSelector" class="form-select" style="font-size: 17px;"
						[formControl]="chooseTemplate" (change)="changeTemplate()">
						<option *ngFor="let t of templates" [ngValue]="t.templateID" [attr.data-cy]="t.templateID">
							{{t.templateName}}
						</option>
					</select>
					<h4 class="text-nowrap mb-0 ms-2">{{charTypeDisplayName}} Record</h4>
				</div>
				<div class="scroll-vert border-top">
					<div class="d-flex justify-content-between align-items-center pb-3">
						<rl-show-only-dropdown [extractedCharIds]="extractedCharIds"></rl-show-only-dropdown>
						<ng-container *rlFeatureEnabled="'aiFeatures'">
							<button *ngIf="showExtractFromPdf()" type="button" class="btn btn-info me-2 mt-2"
								(click)="extractPdf()" data-cy="extract_from_pdf_button">Extract directly from PDF
								(Beta)
							</button>
							<span class="me-2" *ngIf="sourceId()">This form has been filled in automatically from a PDF
								contract.</span>
						</ng-container>
					</div>
					@if(useFieldSections$ | async) {
					@if(layout) {
					<rl-mod-layout-char-data-table [layout]="layout" [charData]="charData" [template]="template"
						[editMode]="true" (tableCharDataChange)="charDataUpdated($event)">
					</rl-mod-layout-char-data-table>
					} @else {
					<rl-loader></rl-loader>
					}
					} @else {
					<rl-char-data-table [charData]="charData" [template]="template" [editMode]="true"
						(tableCharDataChange)="charDataUpdated($event)" [confidenceScores]="confidenceScores" [charDataContext]="charDataContext">
					</rl-char-data-table>
					}
				</div>
				<div class="text-end mt-2" [class.modal-footer]="isSimpleMode()" data-cy="form_actions">
					<button (click)="cancel()" class="btn btn-light me-3">Cancel</button>
					<button (click)="create()" class="btn btn-success" [class.disabled]="!isValid"
						[disabled]="!isValid || isCreating" data-cy="create_button">
						Create
					</button>
					<button *ngIf="canAssociateContacts" type="button" class="btn btn-info ms-3" (click)="continue()"
						[class.disabled]="!isValid" [disabled]="!isValid || isCreating"
						data-cy="continue_associating_button">Continue <i class="far fa-chevron-right ms-2"></i>
					</button>
				</div>
			</div>
		</article>
	</aw-wizard-step>
	<aw-wizard-step *ngIf="canAssociateContacts" stepTitle="Associate Contacts (optional)">
		<rl-associate-contacts [charTypeId]="charTypeId()" [parentCharTypeId]="charTypeId()" [templateId]="templateId()" [extractedParties]="parties"
			(onCancel)="cancel()" (onCreate)="create()" (onBack)="back()" (onGoToNextStep)="continueToNextStep()"
			[canAutoCreateChildItems]="canAutoCreateHierarchy">
		</rl-associate-contacts>
	</aw-wizard-step>
	<aw-wizard-step *ngIf="canAutoCreateHierarchy" stepTitle="Auto-Create Child Items (optional)">
		<rl-create-and-associate-hierarchical-records [templates]="childTemplates" [charTypeId]="charTypeId()"
			[entityId]="createdEntityId" (onCancel)="cancel()" (onHierarchyCompleted)="create($event)"
			(onBack)="back()">
		</rl-create-and-associate-hierarchical-records>
	</aw-wizard-step>
</aw-wizard>